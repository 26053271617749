.demo-logo{
    width: 100%;
    display: flex;
    justify-content: center;
}

.demo-logo img{
    width: 80px !important;
}
.ant-layout-sider-has-trigger{
    max-width: 280px !important;
    width: 280px !important;
}

.ant-menu-dark .ant-menu-item-selected{
    border-radius: 0 !important;
    width: 100% !important;
}   
