.ttj-title {
  font-size: 14px;
  margin-top: 10px;
  color: #555555;
}

.ttj-title2 {
  font-size: 14px;
  margin-top: 10px;
  color: #555555;
}

.card-title {
  padding: 20px 0 15px 15px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.ttj-info {
  margin-top: 50px;
}

.test {
  border-bottom: 1px solid #c9c8c8;
  padding: 10px 0;
}
